// extracted by mini-css-extract-plugin
export var afishaPicnic = "Carousel-module--afishaPicnic--r0QCC";
export var barbie = "Carousel-module--barbie--SBfAt";
export var bazaar = "Carousel-module--bazaar--Awfpr";
export var braun = "Carousel-module--braun--8lKd1";
export var bulgari = "Carousel-module--bulgari--aoqnF";
export var calvinKlein = "Carousel-module--calvinKlein--bz8js";
export var carousel = "Carousel-module--carousel--Wb5tY";
export var carousel__container = "Carousel-module--carousel__container--5mtXQ";
export var carousel__container__clients = "Carousel-module--carousel__container__clients--kp80E";
export var carousel__container__clients__item = "Carousel-module--carousel__container__clients__item--qTePt";
export var carousel__container__clients__item__icon = "Carousel-module--carousel__container__clients__item__icon--4w63C";
export var carousel__container__description = "Carousel-module--carousel__container__description--qlK0B";
export var carousel__container__description__heading = "Carousel-module--carousel__container__description__heading--sy0e3";
export var carousel__container__description_icon = "Carousel-module--carousel__container__description_icon--av6U1";
export var cosmopolitan = "Carousel-module--cosmopolitan--q-MjD";
export var dyson = "Carousel-module--dyson--Xp75M";
export var esteeLauder = "Carousel-module--esteeLauder--Sod4u";
export var glamour = "Carousel-module--glamour--Zhv9R";
export var gq = "Carousel-module--gq--UUnUu";
export var grazia = "Carousel-module--grazia--qOqwI";
export var gymnasium = "Carousel-module--gymnasium--3-QcN";
export var haval = "Carousel-module--haval--tA0m2";
export var honorLogo = "Carousel-module--honorLogo--0OU14";
export var lancel = "Carousel-module--lancel---EFRR";
export var lancome = "Carousel-module--lancome--nSx6K";
export var letual = "Carousel-module--letual--cA2R8";
export var linzMaster = "Carousel-module--linzMaster--Vp9VW";
export var loreal = "Carousel-module--loreal--IAGQK";
export var macCosmeticsLogo = "Carousel-module--macCosmeticsLogo--DX5BM";
export var mooseKnuckles = "Carousel-module--mooseKnuckles--GHUqX";
export var odnoklassnici = "Carousel-module--odnoklassnici--OG0le";
export var peopleTalk = "Carousel-module--peopleTalk--mAnwP";
export var pgLogo = "Carousel-module--pgLogo--4VAhr";
export var psg = "Carousel-module--psg--+3omm";
export var raceLogo = "Carousel-module--raceLogo--V9SVe";
export var roscosmos = "Carousel-module--roscosmos--vwUyi";
export var salvatoreFerragamo = "Carousel-module--salvatoreFerragamo--2wV24";
export var shara = "Carousel-module--shara--J43I-";
export var spartak = "Carousel-module--spartak--Gxa6u";
export var stockman = "Carousel-module--stockman--5m7cI";
export var stories = "Carousel-module--stories--XBn07";
export var tatler = "Carousel-module--tatler--prjqJ";
export var topdog = "Carousel-module--topdog--IWYy6";
export var ufcLogo = "Carousel-module--ufcLogo--gcJd9";
export var vkLogo = "Carousel-module--vkLogo--8dCzo";
export var worldClass = "Carousel-module--worldClass--kFEGX";
export var wrapper = "Carousel-module--wrapper--hM9O+";
export var xFit = "Carousel-module--xFit--x8LM9";