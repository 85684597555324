import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Icon } from 'modules';
import { up } from 'assets/svg';
import * as styles from './GoToTop.module.scss';

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  color: string;
  className?: string;
};

function GoToTop({ onClick, color, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div onClick={onClick} className={styles.container}>
        <Icon
          color={color}
          path={up.path}
          viewBox={up.viewBox}
          className={styles.container__icon}
        />
        <h2 style={{ color: color }} className={styles.container__text}>
          {t('common:up')}
        </h2>
      </div>
    </div>
  );
}

export default GoToTop;
