// extracted by mini-css-extract-plugin
export var button = "Button-module--button--Udhdc";
export var button_black = "Button-module--button_black--l7slw";
export var button_black_disabled = "Button-module--button_black_disabled--vcs4+";
export var button_black_l = "Button-module--button_black_l--JaS5R";
export var button_black_m = "Button-module--button_black_m--f+7Ss";
export var button_black_outlined = "Button-module--button_black_outlined--qR8SD";
export var button_l = "Button-module--button_l--ToM4y";
export var button_white = "Button-module--button_white--L7PhC";
export var button_white_disabled = "Button-module--button_white_disabled--Y24wf";
export var button_white_l = "Button-module--button_white_l--caXbg";
export var button_white_m = "Button-module--button_white_m--fLfk1";
export var button_white_outlined = "Button-module--button_white_outlined--6VJGy";
export var content = "Button-module--content--OgtR-";
export var icon = "Button-module--icon--F6XgC";
export var icon_l = "Button-module--icon_l--wnZZJ";
export var icon_l_black = "Button-module--icon_l_black--IpjQF";
export var icon_l_white = "Button-module--icon_l_white--nDX4P";
export var icon_m = "Button-module--icon_m--5J1Nb";
export var icon_m_black = "Button-module--icon_m_black--krZ-N";
export var icon_m_white = "Button-module--icon_m_white--SpP9d";