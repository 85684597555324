import { TypeIcon } from 'core/types';

export const tatler: TypeIcon = {
  viewBox: '0 0 869.95734 323.45734',
  path:
    '<svg\n' +
    '   version="1.1"\n' +
    '   id="svg2"\n' +
    '   xml:space="preserve"\n' +
    '   width="869.95734"\n' +
    '   height="323.45734"\n' +
    '   viewBox="0 0 869.95734 323.45734"\n' +
    '   sodipodi:docname="tatler.ai"\n' +
    '   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"\n' +
    '   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"\n' +
    '   xmlns="http://www.w3.org/2000/svg"\n' +
    '   xmlns:svg="http://www.w3.org/2000/svg"><defs\n' +
    '     id="defs6"><clipPath\n' +
    '       clipPathUnits="userSpaceOnUse"\n' +
    '       id="clipPath36"><path\n' +
    '         d="M 0,242.593 H 652.468 V 0 H 0 Z"\n' +
    '         id="path34" /></clipPath></defs><sodipodi:namedview\n' +
    '     id="namedview4"\n' +
    '     pagecolor="#ffffff"\n' +
    '     bordercolor="#666666"\n' +
    '     borderopacity="1.0"\n' +
    '     inkscape:pageshadow="2"\n' +
    '     inkscape:pageopacity="0.0"\n' +
    '     inkscape:pagecheckerboard="0" /><g\n' +
    '     id="g8"\n' +
    '     inkscape:groupmode="layer"\n' +
    '     inkscape:label="tatler"\n' +
    '     transform="matrix(1.3333333,0,0,-1.3333333,0,323.45733)"><g\n' +
    '       id="g10"\n' +
    '       transform="translate(33.2275,196.9263)"><path\n' +
    '         d="m 0,0 v -1.129 -1.13 -32.075 H 2 V -2.259 H 17.333 V -157 H 11 v -2.259 H 45.333 V -157 H 39 V -2.259 h 15.333 v -32.075 h 2 V -2.259 -1.129 0 Z"\n' +
    '         style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '         id="path12" /></g><g\n' +
    '       id="g14"\n' +
    '       transform="translate(231.7275,196.9263)"><path\n' +
    '         d="m 0,0 v -1.129 -1.13 -32.075 H 2 V -2.259 H 17.333 V -157 H 11 v -2.259 H 45.333 V -157 H 39 V -2.259 h 15.333 v -32.075 h 2 V -2.259 -1.129 0 Z"\n' +
    '         style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '         id="path16" /></g><g\n' +
    '       id="g18"\n' +
    '       transform="translate(143.2095,97.6021)"><path\n' +
    '         d="M 0,0 6.33,37.674 12.66,0 Z M 44.018,-57.676 14.498,105.25 -12.878,-57.676 h -7.438 v -2.259 h 16.917 v 2.259 h -6.292 l 9.312,55.417 H 13.04 l 9.311,-55.417 h -6.333 v -2.259 h 34.333 v 2.259 z"\n' +
    '         style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '         id="path20" /></g><g\n' +
    '       id="g22"\n' +
    '       transform="translate(391.5605,72.001)"><path\n' +
    '         d="M 0,0 V -32.075 H -22.333 V 122.667 H -16 v 2.258 h -34.333 v -2.258 H -44 V -32.075 h -5.917 v -2.259 H 2 v 1.129 1.13 V 0 Z"\n' +
    '         style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '         id="path24" /></g><g\n' +
    '       id="g26"\n' +
    '       transform="translate(438.2275,196.9263)"><path\n' +
    '         d="M 0,0 V -2.259 H 6.333 V -157 H 0 v -2.259 h 34.333 18 v 1.129 1.13 32.075 h -2 V -157 h -16 H 28 v 96.667 H 41.666 V -79.63 h 2 v 19.297 2.259 18.491 h -2 V -58.074 H 28 v 55.815 h 22.333 v -32.075 h 2 V -2.259 -1.129 0 Z"\n' +
    '         style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '         id="path28" /></g><g\n' +
    '       id="g30"><g\n' +
    '         id="g32"\n' +
    '         clip-path="url(#clipPath36)"><g\n' +
    '           id="g38"\n' +
    '           transform="translate(615.7363,39.9258)"><path\n' +
    '             d="m 0,0 -29.344,102.501 c 2.5,0.667 6.5,1.833 10.5,4.333 4,2.5 10.218,8.324 12.5,11.667 2.418,3.541 8.388,17.115 -1.166,28.333 C -16.176,157.009 -38.343,157 -38.343,157 H -78.01 v -2.258 h 6.834 V 0 h -6.5 v -2.259 h 35.334 V 0 h -7.167 v 154.742 h 10.605 c 10.543,-3.847 18.185,-13.299 19.224,-23.636 1.49,-14.807 -11.541,-28.52 -28.997,-31.309 L -22.177,0 h -7.165 V -2.259 H 6.491 V 0 Z"\n' +
    '             style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '             id="path40" /></g><g\n' +
    '           id="g42"\n' +
    '           transform="translate(615.9775,196.6235)"><path\n' +
    '             d="m 0,0 c -1.792,0 -3.25,-1.458 -3.25,-3.25 0,-1.792 1.458,-3.25 3.25,-3.25 1.792,0 3.25,1.458 3.25,3.25 C 3.25,-1.458 1.792,0 0,0 m 0,-7.5 c -2.344,0 -4.25,1.906 -4.25,4.25 C -4.25,-0.907 -2.344,1 0,1 2.344,1 4.25,-0.907 4.25,-3.25 4.25,-5.594 2.344,-7.5 0,-7.5"\n' +
    '             style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '             id="path44" /></g><g\n' +
    '           id="g46"\n' +
    '           transform="translate(615.2158,193.48)"><path\n' +
    '             d="M 0,0 H 0.512 C 1.047,0 1.387,0.293 1.387,0.735 1.387,1.236 1.024,1.454 0.495,1.46 0.253,1.46 0.081,1.438 0,1.414 Z m -0.5,1.765 c 0.253,0.051 0.615,0.081 0.96,0.081 0.535,0 0.88,-0.098 1.122,-0.317 0.195,-0.173 0.305,-0.437 0.305,-0.736 0,-0.512 -0.323,-0.851 -0.731,-0.989 v -0.017 c 0.299,-0.103 0.478,-0.38 0.57,-0.782 0.126,-0.54 0.218,-0.914 0.298,-1.064 H 1.507 C 1.443,-1.944 1.357,-1.616 1.248,-1.133 1.133,-0.599 0.926,-0.396 0.472,-0.38 H 0 v -1.679 h -0.5 z"\n' +
    '             style="fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
    '             id="path48" /></g></g></g></g></svg>',
};
