import { TypeIcon } from 'core/types';

export const logoBig: TypeIcon = {
  viewBox: '0 0 315 57',
  path:
    '  <path' +
    '    d="M13.0634 46.744V41.608H35.5754V34.024H13.0634V29.176H36.3914V20.92H0.775391V55H36.7274V46.744H13.0634Z"\n' +
    '    fill="white"' +
    '  />' +
    '  <path d="M41.6166 55H75.4086V45.592H53.9046V20.92H41.6166V55Z" fill="white" />' +
    '  <path' +
    '    d="M77.0376 55H90.1896L92.8296 48.52H108.814L111.454 55H124.606L108.766 20.92H92.8776L77.0376 55ZM95.8056 41.128L98.2056 35.224C98.7816 33.784 100.174 30.088 100.75 27.88H100.894C101.518 30.088 102.862 33.784 103.438 35.224L105.838 41.128H95.8056Z"' +
    '    fill="white"' +
    '  />' +
    '  <path' +
    '    d="M127.377 55H138.513V34.792H138.753C139.233 35.992 140.721 38.056 141.681 39.112L156.273 55H168.273V20.92H157.185V41.032H156.945C156.177 39.544 154.977 37.864 154.065 36.808L140.097 20.92H127.377V55Z"' +
    '    fill="white"' +
    '  />' +
    '  <path' +
    '    d="M174.359 55H196.967C209.399 55 217.703 47.8 217.703 37.384C217.703 26.872 210.023 20.92 197.255 20.92H174.359V55ZM186.647 47.032V28.84H195.239C200.903 28.84 204.743 32.056 204.743 37.624C204.743 43.384 201.047 47.032 194.951 47.032H186.647Z"' +
    '    fill="white"' +
    '  />' +
    '  <path d="M233.067 55V20.92H220.779V55H233.067Z" fill="white" />' +
    '  <path' +
    '    d="M258.704 56.152C270.032 56.152 278.24 51.592 280.976 43.768L268.4 40.984C267.44 44.872 264.176 46.984 259.088 46.984C252.8 46.984 249.056 43.576 249.056 37.96C249.056 32.392 252.848 28.888 259.088 28.888C264.128 28.888 267.344 31 268.352 34.936L281.072 32.536C278.768 24.664 270.32 19.768 259.088 19.768C245.6 19.768 236.096 27.4 236.096 38.152C236.096 49.144 244.928 56.152 258.704 56.152Z"' +
    '    fill="white"' +
    '  />' +
    '  <path' +
    '    d="M295.698 19H297.948V14.95H301.893L304.683 19H307.278L304.293 14.68C306.228 14.2 306.873 12.79 306.873 11.605C306.873 10.09 305.958 8.34998 302.778 8.34998H295.698V19ZM297.948 13.375V9.96998H302.313C304.008 9.96998 304.503 10.825 304.503 11.635C304.503 12.49 303.993 13.375 302.313 13.375H297.948Z"' +
    '    fill="white"' +
    '  />' +
    '  <circle cx="301" cy="14" r="13.5" stroke="white" fill="none" />',
};
