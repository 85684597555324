import { TypeIcon } from 'core/types';

export const logoSmall: TypeIcon = {
  viewBox: '0 0 282 38',
  path:
    '<path d="M13.0634 27.7439V22.6079H35.5754V15.0239H13.0634V10.1759H36.3914V1.91994H0.775391V35.9999H36.7274V27.7439H13.0634Z"  fill-rule="evenodd" />\n' +
    '<path d="M41.6166 35.9999H75.4086V26.5919H53.9046V1.91994H41.6166V35.9999Z"  fill-rule="evenodd" />\n' +
    '<path d="M77.0376 35.9999H90.1896L92.8296 29.5199H108.814L111.454 35.9999H124.606L108.766 1.91994H92.8776L77.0376 35.9999ZM95.8056 22.1279L98.2056 16.2239C98.7816 14.7839 100.174 11.0879 100.75 8.87995H100.894C101.518 11.0879 102.862 14.7839 103.438 16.2239L105.838 22.1279H95.8056Z"  fill-rule="evenodd" />\n' +
    '<path d="M127.377 35.9999H138.513V15.7919H138.753C139.233 16.9919 140.721 19.0559 141.681 20.1119L156.273 35.9999H168.273V1.91994H157.185V22.0319H156.945C156.177 20.5439 154.977 18.8639 154.065 17.8079L140.097 1.91994H127.377V35.9999Z"  fill-rule="evenodd" />\n' +
    '<path d="M174.359 35.9999H196.967C209.399 35.9999 217.703 28.7999 217.703 18.3839C217.703 7.87194 210.023 1.91994 197.255 1.91994H174.359V35.9999ZM186.647 28.0319V9.83994H195.239C200.903 9.83994 204.743 13.0559 204.743 18.6239C204.743 24.3839 201.047 28.0319 194.951 28.0319H186.647Z"  fill-rule="evenodd" />\n' +
    '<path d="M233.067 35.9999V1.91994H220.779V35.9999H233.067Z"  fill-rule="evenodd" />\n' +
    '<path d="M258.704 37.1519C270.032 37.1519 278.24 32.5919 280.976 24.7679L268.4 21.9839C267.44 25.8719 264.176 27.9839 259.088 27.9839C252.8 27.9839 249.056 24.5759 249.056 18.9599C249.056 13.3919 252.848 9.88794 259.088 9.88794C264.128 9.88794 267.344 11.9999 268.352 15.9359L281.072 13.5359C278.768 5.66394 270.32 0.767944 259.088 0.767944C245.6 0.767944 236.096 8.39994 236.096 19.1519C236.096 30.1439 244.928 37.1519 258.704 37.1519Z"  fill-rule="evenodd" />\n',
};
