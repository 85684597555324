import {
  bazaar,
  cosmopolitan,
  gqLogo,
  grazia,
  tatler,
  braun,
  lancel,
  lancome,
  barbie,
  glamour,
  dyson,
  peopleTalk,
  loreal,
  esteeLauder,
  pgLogo,
  calvinKlein,
  bulgari,
  macCosmeticsLogo,
  mooseKnuckles,
  haval,
  vkLogo,
  honorLogo,
  salvatoreFerragamo,
  worldClass,
  xFit,
  ufcLogo,
  topdog,
  afishaPicnic,
  shara,
  odnoklassnici,
  stories,
  letual,
  linzMaster,
  spartak,
  psg,
  gymnasium,
  roscosmos,
  stockman,
  raceLogo,
} from 'assets/svg';
import { TypeBrandIcon } from './types';

export const carouselConfig: TypeBrandIcon[] = [
  {
    path: gqLogo.path,
    viewBox: gqLogo.viewBox,
    className: 'gq',
  },
  {
    path: tatler.path,
    viewBox: tatler.viewBox,
    className: 'tatler',
  },
  {
    path: cosmopolitan.path,
    viewBox: cosmopolitan.viewBox,
    className: 'cosmopolitan',
  },
  {
    path: bazaar.path,
    viewBox: bazaar.viewBox,
    className: 'bazaar',
  },
  {
    path: grazia.path,
    viewBox: grazia.viewBox,
    className: 'grazia',
  },
  {
    path: braun.path,
    viewBox: braun.viewBox,
    className: 'braun',
  },
  {
    path: lancome.path,
    viewBox: lancome.viewBox,
    className: 'lancome',
  },
  {
    path: barbie.path,
    viewBox: barbie.viewBox,
    className: 'barbie',
  },
  {
    path: glamour.path,
    viewBox: glamour.viewBox,
    className: 'glamour',
  },
  {
    path: dyson.path,
    viewBox: dyson.viewBox,
    className: 'dyson',
  },
  {
    path: peopleTalk.path,
    viewBox: peopleTalk.viewBox,
    className: 'peopleTalk',
  },
  {
    path: loreal.path,
    viewBox: loreal.viewBox,
    className: 'loreal',
  },
  {
    path: esteeLauder.path,
    viewBox: esteeLauder.viewBox,
    className: 'esteeLauder',
  },
  {
    path: lancel.path,
    viewBox: lancel.viewBox,
    className: 'lancel',
  },
  {
    path: pgLogo.path,
    viewBox: pgLogo.viewBox,
    className: 'pgLogo',
  },
  // {
  //   path: calvinKlein.path,
  //   viewBox: calvinKlein.viewBox,
  //   className: 'calvinKlein',
  // },
  {
    path: bulgari.path,
    viewBox: bulgari.viewBox,
    className: 'bulgari',
  },
  // {
  //   path: macCosmeticsLogo.path,
  //   viewBox: macCosmeticsLogo.viewBox,
  //   className: 'macCosmeticsLogo',
  // },
  // {
  //   path: mooseKnuckles.path,
  //   viewBox: mooseKnuckles.viewBox,
  //   className: 'mooseKnuckles',
  // },
  // {
  //   path: haval.path,
  //   viewBox: haval.viewBox,
  //   className: 'haval',
  // },
  // {
  //   path: vkLogo.path,
  //   viewBox: vkLogo.viewBox,
  //   className: 'vkLogo',
  // },
  // {
  //   path: honorLogo.path,
  //   viewBox: honorLogo.viewBox,
  //   className: 'honorLogo',
  // },
  {
    path: salvatoreFerragamo.path,
    viewBox: salvatoreFerragamo.viewBox,
    className: 'salvatoreFerragamo',
  },
  {
    path: worldClass.path,
    viewBox: worldClass.viewBox,
    className: 'worldClass',
  },
  // {
  //   path: xFit.path,
  //   viewBox: xFit.viewBox,
  //   className: 'xFit',
  // },
  // {
  //   path: ufcLogo.path,
  //   viewBox: ufcLogo.viewBox,
  //   className: 'ufcLogo',
  // },
  // {
  //   path: topdog.path,
  //   viewBox: topdog.viewBox,
  //   className: 'topdog',
  // },
  // {
  //   path: afishaPicnic.path,
  //   viewBox: afishaPicnic.viewBox,
  //   className: 'afishaPicnic',
  // },
  // {
  //   path: odnoklassnici.path,
  //   viewBox: odnoklassnici.viewBox,
  //   className: 'odnoklassnici',
  // },
  // {
  //   path: shara.path,
  //   viewBox: shara.viewBox,
  //   className: 'shara',
  // },
  {
    path: stories.path,
    viewBox: stories.viewBox,
    className: 'stories',
  },
  // {
  //   path: letual.path,
  //   viewBox: letual.viewBox,
  //   className: 'letual',
  // },
  // {
  //   path: linzMaster.path,
  //   viewBox: linzMaster.viewBox,
  //   className: 'linzMaster',
  // },
  {
    path: spartak.path,
    viewBox: spartak.viewBox,
    className: 'spartak',
  },
  // {
  //   path: psg.path,
  //   viewBox: psg.viewBox,
  //   className: 'psg',
  // },
  // {
  //   path: gymnasium.path,
  //   viewBox: gymnasium.viewBox,
  //   className: 'gymnasium',
  // },
  {
    path: roscosmos.path,
    viewBox: roscosmos.viewBox,
    className: 'roscosmos',
  },
  // {
  //   path: stockman.path,
  //   viewBox: stockman.viewBox,
  //   className: 'stockman',
  // },
  // {
  //   path: raceLogo.path,
  //   viewBox: raceLogo.viewBox,
  //   className: 'raceLogo',
  // },
];
