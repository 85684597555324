import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useI18next, Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { Squash as Hamburger } from 'hamburger-react';
import { Icon, Link } from 'modules';
import { logo } from 'assets/svg';
import * as styles from './Header.module.scss';

type Props = {
  isOpenedSidebar: boolean;
  onToggleSidebar: () => void;
};

function Header({ isOpenedSidebar, onToggleSidebar }: Props): JSX.Element {
  const [color, setColor] = useState<string>('none');
  const { originalPath, language } = useI18next();

  const changeColor = () => {
    if (window.scrollY > 10) {
      setColor('black');
    } else {
      setColor('transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColor);

    return () => {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  return (
    <>
      <div
        className={classNames(styles.logo, styles.logo_upper, styles.hamburger)}
      >
        <Hamburger
          color={isOpenedSidebar ? 'black' : 'white'}
          duration={0.9}
          toggled={isOpenedSidebar}
          onToggle={onToggleSidebar}
        />
      </div>
      <header className={styles.header} style={{ backgroundColor: color }}>
        <div className={styles.hamburger__empty} />
        <GatsbyLink to="/" className={styles.logo}>
          <Icon
            className={styles.logo__icon}
            viewBox={logo.viewBox}
            path={logo.path}
          />
        </GatsbyLink>
        <div className={styles.languages}>
          <Link
            isDisabled={language === 'en'}
            theme="white"
            to={originalPath}
            language="en"
          >
            EN
          </Link>
          <span className={styles.separator}>|</span>
          <Link
            isDisabled={language === 'ru'}
            theme="white"
            to={originalPath}
            language="ru"
          >
            RU
          </Link>
        </div>
      </header>
    </>
  );
}

export default memo(Header);
