import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as styles from './ProcessOfWorkItem.module.scss';
import { Icon } from '../../modules';
import { bracketLeft, bracketRight } from 'assets/svg';

type Props = {
  id: number;
  title: string;
  text: string;
};

function ProcessOfWorkItem({ id, title, text }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.counter}>
          <Icon
            className={styles.bracket}
            viewBox={bracketLeft.viewBox}
            path={bracketLeft.path}
          />
          <h6 className={styles.wrapper__id}>{id}</h6>

          <Icon
            className={styles.bracket}
            viewBox={bracketRight.viewBox}
            path={bracketRight.path}
          />
        </div>
        <h1 className={styles.wrapper__text}>{t(title)}</h1>
        <p className={styles.wrapper__description}>{t(text)}</p>
      </div>
    </div>
  );
}

export default memo(ProcessOfWorkItem);
