import { TypeIcon } from 'core/types';

export const roscosmos: TypeIcon = {
  viewBox: '0 0 127 80',
  path:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M63.4142 12.098L63.7573 12.0031C46.687 17.2905 33.181 26.3487 32.9031 33.0016C32.6057 40.123 47.5761 41.793 66.3318 36.7347C85.0876 31.6764 100.538 21.7985 100.834 14.6779C100.987 10.9883 97.0368 8.76337 90.5882 8.17773C98.4706 8.52912 103.408 10.8222 103.408 14.9159C103.408 22.7185 85.499 34.1096 63.4142 40.3582C41.3293 46.6067 23.4199 45.3413 23.4199 37.5389C23.4199 29.7365 41.3293 18.3451 63.4142 12.0987" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0977 53.1229L87.5411 0L82.5155 41.7601L73.348 31.2835L32.0977 53.1229Z" fill="black"/>\n' +
    '<path d="M10.1252 70.8612C10.1252 73.1058 8.50713 75.2727 5.70613 75.2727H2.86405V79.6847H0.337891V66.4492H5.07336C8.52661 66.4492 10.1252 68.3001 10.1252 70.8612ZM7.59928 70.8612C7.59928 69.6196 6.61351 68.9703 5.38974 68.9703H2.86405V72.7516H5.38974C6.61421 72.7516 7.59928 71.9638 7.59928 70.8612Z" fill="black"/>\n' +
    '<path d="M25.6799 73.0644C25.6803 74.436 25.2733 75.7768 24.5102 76.9174C23.7471 78.058 22.6622 78.9471 21.3928 79.4723C20.1234 79.9975 18.7264 80.1351 17.3786 79.8678C16.0308 79.6005 14.7927 78.9403 13.8208 77.9707C12.849 77.001 12.1871 75.7655 11.9188 74.4204C11.6506 73.0753 11.788 71.681 12.3138 70.4138C12.8395 69.1467 13.73 68.0636 14.8725 67.3016C16.0151 66.5395 17.3584 66.1328 18.7325 66.1328C20.5737 66.1358 22.3387 66.867 23.6409 68.1662C24.9431 69.4654 25.6763 71.2268 25.6799 73.0644ZM23.1537 73.0644C23.1534 72.1919 22.894 71.3391 22.4081 70.6138C21.9223 69.8885 21.2319 69.3232 20.4242 68.9894C19.6165 68.6557 18.7278 68.5684 17.8704 68.7387C17.013 68.909 16.2255 69.3292 15.6074 69.9462C14.9893 70.5632 14.5683 71.3492 14.3977 72.2049C14.2271 73.0606 14.3146 73.9476 14.649 74.7538C14.9835 75.5599 15.5499 76.2489 16.2766 76.7338C17.0034 77.2187 17.8579 77.4776 18.732 77.4778C19.3131 77.479 19.8886 77.3657 20.4256 77.1443C20.9626 76.923 21.4506 76.5979 21.8614 76.1879C22.2722 75.7778 22.5979 75.2908 22.8197 74.7548C23.0414 74.2188 23.1549 73.6443 23.1537 73.0644Z" fill="black"/>\n' +
    '<path d="M37.8166 76.119L38.7829 78.4048C37.5831 79.4278 36.0587 79.9931 34.4807 80.0004C30.5928 80.0004 27.416 76.8873 27.416 73.0664C27.416 69.2454 30.5928 66.1348 34.4807 66.1348C35.9608 66.1348 37.6589 66.6857 38.7829 67.7101L37.8157 69.9951C36.9356 69.135 35.7523 68.6543 34.5206 68.6563C31.9947 68.6563 29.9422 70.624 29.9422 73.0664C29.9422 75.5087 31.9947 77.4798 34.5206 77.4798C35.1334 77.4852 35.741 77.3676 36.3072 77.1338C36.8735 76.9 37.3868 76.5549 37.8166 76.119Z" fill="black"/>\n' +
    '<path d="M52.787 79.6847H49.5898L44.5762 73.8941V79.6847H42.0508V66.4492H44.5762V71.925L49.2742 66.4492H52.4716L46.7862 72.7711L52.787 79.6847Z" fill="black"/>\n' +
    '<path d="M67.2052 73.0644C67.2057 74.436 66.7986 75.7769 66.0355 76.9175C65.2723 78.0582 64.1873 78.9473 62.9178 79.4724C61.6483 79.9975 60.2513 80.1351 58.9035 79.8677C57.5556 79.6003 56.3175 78.9399 55.3457 77.9702C54.3739 77.0004 53.7121 75.7647 53.444 74.4195C53.1759 73.0743 53.3136 71.68 53.8396 70.4128C54.3655 69.1457 55.2562 68.0627 56.3989 67.3009C57.5417 66.539 58.8851 66.1325 60.2593 66.1328C62.1004 66.1358 63.8653 66.867 65.1672 68.1663C66.4692 69.4655 67.202 71.2269 67.2052 73.0644ZM64.6798 73.0644C64.6795 72.1919 64.42 71.339 63.9341 70.6137C63.4482 69.8883 62.7577 69.323 61.95 68.9893C61.1422 68.6556 60.2534 68.5684 59.3961 68.7388C58.5387 68.9092 57.7511 69.3295 57.1331 69.9466C56.515 70.5637 56.0941 71.3498 55.9237 72.2056C55.7532 73.0614 55.8408 73.9485 56.1754 74.7546C56.5101 75.5607 57.0767 76.2496 57.8036 76.7344C58.5305 77.2191 59.3851 77.4778 60.2593 77.4778C60.8402 77.4788 61.4155 77.3653 61.9523 77.1438C62.4891 76.9223 62.9768 76.5972 63.3875 76.1872C63.7981 75.7771 64.1236 75.2902 64.3452 74.7543C64.5669 74.2185 64.6803 73.6442 64.6791 73.0644H64.6798Z" fill="black"/>\n' +
    '<path d="M79.3426 76.1199L80.3098 78.4049C79.1101 79.4277 77.5859 79.993 76.0081 80.0004C72.1201 80.0004 68.9434 76.8873 68.9434 73.0664C68.9434 69.2455 72.1201 66.1348 76.0081 66.1348C77.4884 66.1348 79.1846 66.6857 80.3098 67.7101L79.3426 69.9951C78.4626 69.1351 77.2795 68.6544 76.048 68.6563C73.522 68.6563 71.4691 70.6241 71.4691 73.0664C71.4691 75.5088 73.522 77.4798 76.048 77.4798C76.6604 77.4852 77.2677 77.3675 77.8337 77.1339C78.3997 76.9003 78.9128 76.5555 79.3426 76.1199Z" fill="black"/>\n' +
    '<path d="M98.1424 79.6847H95.4583L94.3535 71.0186L90.8815 79.6847H89.6187L86.1451 71.0186L85.0398 79.6847H82.3555L84.2503 66.4492H86.776L90.2496 74.9583L93.7233 66.4492H96.2492L98.1424 79.6847Z" fill="black"/>\n' +
    '<path d="M113.387 73.0644C113.387 74.436 112.98 75.7768 112.217 76.9174C111.454 78.058 110.369 78.9471 109.1 79.4723C107.83 79.9975 106.433 80.1351 105.086 79.8678C103.738 79.6005 102.5 78.9403 101.528 77.9707C100.556 77.001 99.8941 75.7655 99.6259 74.4204C99.3576 73.0753 99.495 71.681 100.021 70.4138C100.547 69.1467 101.437 68.0636 102.58 67.3016C103.722 66.5395 105.065 66.1328 106.44 66.1328C108.281 66.1354 110.046 66.8664 111.348 68.1657C112.651 69.465 113.384 71.2266 113.387 73.0644ZM110.861 73.0644C110.861 72.1919 110.601 71.339 110.115 70.6137C109.629 69.8883 108.939 69.323 108.131 68.9893C107.323 68.6556 106.435 68.5684 105.577 68.7388C104.72 68.9092 103.932 69.3295 103.314 69.9466C102.696 70.5637 102.275 71.3498 102.105 72.2056C101.934 73.0614 102.022 73.9485 102.357 74.7546C102.691 75.5607 103.258 76.2496 103.985 76.7344C104.712 77.2191 105.566 77.4778 106.44 77.4778C107.021 77.4789 107.597 77.3655 108.134 77.1441C108.671 76.9226 109.159 76.5976 109.569 76.1875C109.98 75.7775 110.306 75.2905 110.527 74.7546C110.749 74.2186 110.862 73.6443 110.861 73.0644H110.861Z" fill="black"/>\n' +
    '<path d="M125.525 76.1199L126.492 78.4049C125.292 79.4277 123.768 79.993 122.19 80.0004C118.302 80.0004 115.125 76.8873 115.125 73.0664C115.125 69.2455 118.302 66.1348 122.19 66.1348C123.67 66.1348 125.366 66.6857 126.492 67.7101L125.525 69.9951C124.645 69.135 123.461 68.6543 122.23 68.6563C119.704 68.6563 117.651 70.6241 117.651 73.0664C117.651 75.5088 119.704 77.4798 122.23 77.4798C122.842 77.4851 123.449 77.3675 124.016 77.1339C124.582 76.9003 125.095 76.5554 125.525 76.1199Z" fill="black"/>\n',
};
