import { TypeIcon } from 'core/types';

export const worldClass: TypeIcon = {
  viewBox: '0 0 176 50',
  path:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M41.4142 11.8423C44.5355 11.1399 47.8633 11.2113 50.8193 12.4996L50.962 11.5869L61.5652 11.6545C63.139 11.3277 64.8029 11.3052 66.3992 11.677L66.7523 11.7747L67.8077 5.13404H81.4871L80.4542 11.538C81.2279 11.4216 82.0017 11.3991 82.7979 11.4216L83.8083 5.13404H97.4389L96.5938 10.3887C96.8755 9.91919 97.1572 9.49852 97.4839 9.07409C99.9028 6.18948 102.998 5.1115 106.66 4.85234C108.887 4.78097 111.092 4.89741 113.158 5.81387L113.252 5.13404H126.931L125.8 12.0789C126.578 11.8423 127.329 11.7033 128.103 11.5869C131.69 11.1399 135.468 11.3502 138.285 13.8855C140.937 11.6545 144.761 11.2338 148.092 11.4667C150.271 11.677 152.525 12.2667 154.166 13.8367C156.044 12.3118 158.391 11.7033 160.761 11.4667C164.397 11.2338 168.082 11.6319 170.613 14.494C171.763 15.9739 172.21 17.4049 172.304 19.2566C172.304 19.9139 172.255 20.5262 172.161 21.1797L170.801 29.7209H170.05C170.448 31.0806 170.471 32.3501 170.287 33.7323L169.535 38.3785C168.901 41.218 167.586 43.3289 165.148 44.9477C162.121 46.7318 158.557 47.0361 155.105 46.5891C153.298 46.3074 151.398 45.7215 150.038 44.4294C147.082 46.6117 143.33 47.0098 139.788 46.6605C138.635 46.5178 137.579 46.2849 136.501 45.8379L136.407 46.4013H126.999C125.357 46.7995 123.738 46.822 122.071 46.683C121.485 46.5891 120.876 46.4952 120.313 46.3074V46.4013H106.634L106.705 45.9543C103.865 46.9196 100.816 46.8934 97.9309 46.191C94.9298 45.3233 92.5598 43.4942 91.8086 40.3241L90.8471 46.4013H82.5162L81.9528 46.2623C79.6542 46.9873 77.1677 46.9647 74.9141 46.0745L74.869 46.4013H45.3768L45.4031 46.236C44.6519 46.4501 43.9007 46.5666 43.1007 46.6605C39.3484 47.0098 35.2206 46.6605 32.5463 43.682L31.7951 46.4013H5.1875L7.06551 5.13404H43.2885L41.4142 11.8423Z" fill="white"/>\n' +
    '<path d="M41.4142 11.8423C44.5317 11.1399 47.8633 11.2113 50.8193 12.4996L50.962 11.5869L61.5652 11.6545C63.139 11.3277 64.8029 11.3052 66.3992 11.677L66.7523 11.7747L67.8077 5.13404H81.4871L80.4542 11.538C81.2279 11.4216 82.0017 11.3991 82.7979 11.4216L83.8083 5.13404H97.4389L96.5937 10.3887C96.8754 9.91919 97.1572 9.49852 97.4877 9.07409C99.9028 6.18948 102.998 5.1115 106.66 4.85234C108.887 4.78097 111.092 4.89741 113.158 5.81387L113.252 5.13404H126.927L125.804 12.0789C126.578 11.8423 127.329 11.7033 128.103 11.5869C131.69 11.1399 135.468 11.3502 138.285 13.8855C140.937 11.6545 144.761 11.2338 148.092 11.4667C150.271 11.677 152.524 12.2667 154.166 13.8367C156.044 12.3118 158.391 11.7033 160.761 11.4667C164.397 11.2338 168.082 11.6319 170.613 14.494C171.763 15.9739 172.21 17.4049 172.304 19.2566C172.304 19.9139 172.255 20.5262 172.161 21.1797L170.801 29.7209H170.05C170.448 31.0806 170.474 32.3501 170.287 33.7323L169.535 38.3785C168.901 41.218 167.586 43.3289 165.148 44.9477C162.121 46.7318 158.557 47.0361 155.105 46.5891C153.298 46.3074 151.398 45.7215 150.038 44.4294C147.082 46.6117 143.33 47.0098 139.784 46.6605C138.635 46.5178 137.579 46.2849 136.501 45.8379L136.407 46.4013H126.999C125.357 46.7995 123.738 46.822 122.071 46.683C121.485 46.5891 120.876 46.4952 120.313 46.3074V46.4013H106.634L106.705 45.9543C103.865 46.9196 100.816 46.8934 97.9309 46.191C94.9298 45.3233 92.5598 43.4942 91.8086 40.3241L90.8471 46.4013H82.5162L81.9528 46.2623C79.6541 46.9873 77.1677 46.9647 74.9141 46.0745L74.869 46.4013H45.3768L45.4031 46.2361C44.6519 46.4501 43.9007 46.5666 43.1044 46.6605C39.3484 47.0098 35.2206 46.6605 32.5463 43.682L31.7951 46.4013H5.1875L7.06551 5.13404H43.2885L41.4142 11.8423Z" stroke="black" stroke-width="5.7099" stroke-miterlimit="2.6131" stroke-linejoin="round"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M41.4142 11.8423C44.5355 11.1399 47.8633 11.2113 50.8193 12.4996L50.962 11.5869L61.5652 11.6545C63.139 11.3277 64.8029 11.3052 66.3992 11.677L66.7523 11.7747L67.8077 5.13404H81.4871L80.4542 11.538C81.2279 11.4216 82.0017 11.3991 82.7979 11.4216L83.8083 5.13404H97.4389L96.5938 10.3887C96.8755 9.91919 97.1572 9.49852 97.4839 9.07409C99.9028 6.18948 102.998 5.1115 106.66 4.85234C108.887 4.78097 111.092 4.89741 113.158 5.81387L113.252 5.13404H126.931L125.8 12.0789C126.578 11.8423 127.329 11.7033 128.103 11.5869C131.69 11.1399 135.468 11.3502 138.285 13.8855C140.937 11.6545 144.761 11.2338 148.092 11.4667C150.271 11.677 152.525 12.2667 154.166 13.8367C156.044 12.3118 158.391 11.7033 160.761 11.4667C164.397 11.2338 168.082 11.6319 170.613 14.494C171.763 15.9739 172.21 17.4049 172.304 19.2566C172.304 19.9139 172.255 20.5262 172.161 21.1797L170.801 29.7209H170.05C170.448 31.0806 170.471 32.3501 170.287 33.7323L169.535 38.3785C168.901 41.218 167.586 43.3289 165.148 44.9477C162.121 46.7318 158.557 47.0361 155.105 46.5891C153.298 46.3074 151.398 45.7215 150.038 44.4294C147.082 46.6117 143.33 47.0098 139.788 46.6605C138.635 46.5178 137.579 46.2849 136.501 45.8379L136.407 46.4013H126.999C125.357 46.7995 123.738 46.822 122.071 46.683C121.485 46.5891 120.876 46.4952 120.313 46.3074V46.4013H106.634L106.705 45.9543C103.865 46.9196 100.816 46.8934 97.9309 46.191C94.9298 45.3233 92.5598 43.4942 91.8086 40.3241L90.8471 46.4013H82.5162L81.9528 46.2623C79.6542 46.9873 77.1677 46.9647 74.9141 46.0745L74.869 46.4013H45.3768L45.4031 46.236C44.6519 46.4501 43.9007 46.5666 43.1007 46.6605C39.3484 47.0098 35.2206 46.6605 32.5463 43.682L31.7951 46.4013H5.1875L7.06551 5.13404H43.2885L41.4142 11.8423Z" fill="white"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M60.6263 26.6486H66.8688L68.0895 18.9751C68.2773 17.8032 67.9956 16.8642 67.2218 16.1618C67.0791 16.0416 66.9627 15.9252 66.8237 15.8313C66.1176 15.3392 65.2274 15.0801 64.1945 15.0801C62.4592 15.0801 61.1221 15.4557 60.1831 16.1843L60.322 15.2679H54.1058L50.7968 35.8433L49.6963 42.7168H55.9125L59.4544 20.6427C59.5971 19.8427 60.0178 19.422 60.7202 19.422C61.4488 19.422 61.7305 19.8427 61.5916 20.6878L60.6263 26.6486Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M173.171 5.1561C173.336 4.99084 173.43 4.75796 173.43 4.45373C173.43 4.07812 173.31 3.81896 173.1 3.6537C172.889 3.49219 172.562 3.39453 172.116 3.39453H170.707V7.10547H171.27V5.48287H171.928L171.95 5.5317L172.889 7.10547H173.524L172.469 5.46034C172.773 5.4378 172.983 5.32136 173.171 5.1561ZM171.27 5.01713V3.86779H172.044C172.281 3.86779 172.469 3.91286 172.585 3.98422C172.747 4.05559 172.844 4.2171 172.844 4.4049C172.844 4.66406 172.747 4.85186 172.562 4.92323C172.442 4.99083 172.232 5.01713 171.95 5.01713H171.27Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M174.227 2.95162C173.592 2.34315 172.845 2.03516 171.951 2.03516C171.06 2.03516 170.287 2.34315 169.652 2.95162C169.017 3.5601 168.69 4.33759 168.69 5.22776C168.69 6.16677 169.017 6.91797 169.652 7.54898C170.287 8.13492 171.038 8.44291 171.951 8.44291C172.845 8.44291 173.618 8.13492 174.249 7.52644C174.884 6.91797 175.188 6.16677 175.188 5.22776C175.188 4.33759 174.862 3.58639 174.227 2.95162ZM173.806 7.15084C173.31 7.66541 172.679 7.92458 171.951 7.92458C171.199 7.92458 170.591 7.66541 170.099 7.15084C169.581 6.63627 169.348 6.0015 169.348 5.22776C169.348 4.47656 169.581 3.8418 170.099 3.34976C170.591 2.83519 171.199 2.57602 171.951 2.57602C172.679 2.57602 173.288 2.83519 173.806 3.34976C174.298 3.86809 174.554 4.47656 174.554 5.22776C174.554 6.0015 174.298 6.63627 173.806 7.15084Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M38.4579 8.81836H32.8014L27.9937 24.4659L28.6247 8.81836H20.861L16.0045 24.4659L16.7557 8.81836H10.5846L9.03711 42.7163H16.0496L21.3756 24.0189L20.8385 42.7163H29.0003L36.4372 16.0186L38.4579 8.81836Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M51.8295 20.6427C52.0887 18.9751 51.7356 17.7093 50.7966 16.8154C50.6577 16.6764 50.4924 16.5337 50.3271 16.4172C49.0163 15.527 47.42 15.0801 45.4969 15.0801C44.6518 15.0801 43.8781 15.1289 43.1494 15.2453C39.6751 15.8087 37.6844 17.6867 37.1661 20.8756L36.4374 25.4279L34.5406 37.2969C34.165 39.6218 34.796 41.2632 36.4374 42.1797C37.5191 42.7882 39.0441 43.0924 41.0122 43.0924C41.7897 43.0924 42.4921 43.0473 43.1494 42.9534C46.62 42.4614 48.6144 40.6547 49.1102 37.556L50.7966 27.0242L51.8295 20.6427ZM45.5908 20.6878L43.1494 35.8433L42.8902 37.4847C42.7513 38.4237 42.3043 38.8932 41.6019 38.8932C40.8733 38.8932 40.5916 38.4237 40.7343 37.4847L43.1494 22.3292L43.4311 20.6878C43.5701 19.7751 43.9945 19.3281 44.7194 19.3281C45.4481 19.3281 45.7298 19.7751 45.5908 20.6878Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M77.1674 8.81836H70.9512L66.8234 34.5057L65.5088 42.7163H71.7475L75.4547 19.5155L77.1674 8.81836Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M136.783 20.8756C137.042 19.2568 136.828 17.9684 136.171 17.0294C135.258 15.7374 133.452 15.0801 130.751 15.0801C129.485 15.0801 128.336 15.1965 127.352 15.4557C124.512 16.1618 122.894 17.8971 122.45 20.6427L121.556 26.1303H127.562C127.634 25.7321 127.705 25.1462 127.75 24.3724C127.844 23.5987 127.915 23.0128 127.987 22.6109C128.336 20.4286 128.899 19.3281 129.722 19.3281C130.447 19.3281 130.729 19.7751 130.59 20.6878L129.932 24.748C129.861 25.2175 129.042 25.8486 127.468 26.6486C127.423 26.6711 127.397 26.6711 127.352 26.6937C124.7 28.0308 122.894 29.1576 121.932 30.0741C121.534 30.4497 121.181 30.8703 120.922 31.3173C120.546 31.9258 120.313 32.5831 120.197 33.2855L119.303 38.7993C118.999 40.6998 119.539 41.9693 120.922 42.6004C121.628 42.9309 122.567 43.0924 123.716 43.0924C125.38 43.0924 126.601 42.8107 127.352 42.2736C127.585 42.0858 127.799 41.898 127.938 41.6614L127.776 42.7168H133.264L136.171 24.6767L136.783 20.8756ZM127.867 37.5786C127.799 38.0744 127.634 38.4237 127.352 38.6378C127.164 38.7767 126.931 38.8481 126.65 38.8481C125.898 38.8481 125.59 38.4237 125.733 37.5786L126.529 32.6056C126.623 32.0685 126.882 31.6215 127.352 31.2459C127.75 30.9417 128.314 30.7051 129.016 30.5173L127.867 37.5786Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M149.358 28.7369C146.192 26.904 144.502 25.5931 144.265 24.8419C144.055 24.0907 144.055 22.9639 144.314 21.4841C144.524 20.0568 145.043 19.3281 145.816 19.3281C146.59 19.3281 146.778 20.4775 146.425 22.7536L145.745 26.0626H151.822L152.033 24.7218L152.69 20.6427C152.949 19.069 152.735 17.8483 152.033 16.9581C151.094 15.7148 149.264 15.0801 146.519 15.0801C141.685 15.0801 138.965 17.0032 138.353 20.8756L137.767 24.5602C137.534 26.0364 137.673 27.1181 138.237 27.8655C138.778 28.5942 140.536 29.8863 143.491 31.738C144.243 32.3953 144.43 33.8977 144.055 36.2189C143.773 38.003 143.281 38.8932 142.579 38.8932C141.873 38.8932 141.617 38.4237 141.756 37.4847L142.695 31.6666H136.479L136.171 33.6611L135.54 37.556C135.307 39.0584 135.517 40.2566 136.171 41.1468C137.11 42.4614 139.011 43.0924 141.873 43.0924C146.778 43.0924 149.546 41.1693 150.155 37.2969L150.812 33.1916C151.116 31.3398 150.624 29.86 149.358 28.7369Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M85.776 16.09C85.0962 15.4778 84.067 15.151 82.6097 15.1059C82.4482 15.0796 82.2604 15.0796 82.0951 15.0796C80.8293 15.0796 79.7251 15.4102 78.7147 16.1125C77.7306 16.7924 77.1447 17.7314 76.9569 18.8807L75.4545 28.2407L73.7906 38.611C73.5765 39.997 73.9521 41.075 74.9399 41.8975C75.1052 42.014 75.2667 42.1529 75.4545 42.2468C76.371 42.8102 77.4715 43.0919 78.8086 43.0919C80.1458 43.0919 81.4153 42.6713 82.6097 41.8262C82.6097 41.8036 82.636 41.8036 82.636 41.8036L83.0078 42.7163H87.7028L93.1453 8.81836H86.9253L85.776 16.09ZM85.0511 20.5484L82.6097 35.8203L82.328 37.5781C82.189 38.4232 81.7646 38.8477 81.0622 38.8477C80.3336 38.8477 80.0293 38.4232 80.1458 37.5781L82.6097 22.3287L82.8914 20.5934C83.0078 19.7483 83.4548 19.3277 84.1835 19.3277C84.8858 19.3277 85.1901 19.7258 85.0511 20.5484Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M162.354 15.0801C157.524 15.0801 154.801 17.0032 154.192 20.8756L153.603 24.5602C153.37 26.0364 153.509 27.1181 154.072 27.8655C154.613 28.5942 156.375 29.8863 159.331 31.738C160.078 32.3953 160.27 33.8977 159.894 36.2189C159.612 38.003 159.117 38.8932 158.414 38.8932C157.712 38.8932 157.453 38.4237 157.595 37.4847L158.531 31.6666H152.314L152.033 33.4507L151.375 37.556C151.143 39.081 151.353 40.3017 152.033 41.1919C152.994 42.4614 154.895 43.0924 157.712 43.0924C162.613 43.0924 165.382 41.1693 165.994 37.2969L166.647 33.1916C166.955 31.3398 166.46 29.86 165.194 28.7369C162.028 26.904 160.337 25.5931 160.104 24.8419C159.894 24.0907 159.894 22.9639 160.15 21.4841C160.36 20.0568 160.878 19.3281 161.652 19.3281C162.426 19.3281 162.613 20.4775 162.264 22.7536L161.584 26.0626H167.658L168.525 20.6427C169.138 16.9355 167.072 15.0801 162.354 15.0801Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M122.612 8.81836H116.396L112.523 32.8869L110.953 42.7163H117.169L120.922 19.3502L122.612 8.81836Z" fill="black"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M105.368 29.1125L104.031 37.4621C103.937 38.0255 103.584 38.3072 102.976 38.3072C102.318 38.3072 102.014 38.0255 102.108 37.4621L105.883 13.957C105.981 13.3936 106.352 13.1119 107.01 13.1119C107.622 13.1119 107.904 13.3936 107.81 13.957L106.728 20.6652H112.993L113.977 14.5166C114.353 12.2668 113.861 10.6479 112.524 9.66384C111.491 8.88634 109.921 8.51074 107.832 8.51074C102.529 8.51074 99.5277 10.693 98.8216 15.1063L95.3285 36.925C94.6712 41.004 96.9924 43.0247 102.296 43.0247C104.125 43.0247 105.86 42.5552 107.457 41.5674C109.076 40.6059 110.015 39.3175 110.274 37.6987L111.656 29.1125H105.368Z" fill="black"/>\n',
};
