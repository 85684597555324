import { TypeIcon } from 'core/types';

export const up: TypeIcon = {
  viewBox: 'viewBox="0 0 64 64',
  path:
    '<g clip-path="url(#clip0_982_950)">\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M42.7073 25.3829L32.0002 14.6758L21.2931 25.3829L22.7073 26.7971L31.0001 18.5043L31.0001 50H33.0001L33.0001 18.5041L41.2931 26.7971L42.7073 25.3829Z"/>\n' +
    '</g>\n' +
    '<rect x="1" y="1"  fill="none" width="62" height="62" rx="31" stroke-width="2"/>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_982_950">\n' +
    '<rect width="36" height="24" fill="white" transform="translate(20 50) rotate(-90)"/>\n' +
    '</clipPath>\n' +
    '</defs>',
};
