// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--LZE0a";
export var footer__wrapper = "Footer-module--footer__wrapper--T945J";
export var footer__wrapper__logos = "Footer-module--footer__wrapper__logos--jeaoO";
export var footer__wrapper__logos__heading = "Footer-module--footer__wrapper__logos__heading--779pv";
export var footer__wrapper__logos__icon = "Footer-module--footer__wrapper__logos__icon--EvHTl";
export var footer__wrapper__logos__owner = "Footer-module--footer__wrapper__logos__owner--XkQ35";
export var footer__wrapper__media = "Footer-module--footer__wrapper__media--DBan1";
export var footer__wrapper__media__main = "Footer-module--footer__wrapper__media__main--xiM3N";
export var footer__wrapper__media__main__bottom = "Footer-module--footer__wrapper__media__main__bottom--sYGGh";
export var footer__wrapper__media__main__links = "Footer-module--footer__wrapper__media__main__links--MDl4W";
export var footer__wrapper__media__privacy = "Footer-module--footer__wrapper__media__privacy--r8q4M";
export var footer__wrapper__media__separator = "Footer-module--footer__wrapper__media__separator--OUEYW";
export var footer__wrapper__text = "Footer-module--footer__wrapper__text--OPk6D";
export var ru = "Footer-module--ru--In7wj";