// extracted by mini-css-extract-plugin
export var sidebar = "Sidebar-module--sidebar--G-oUa";
export var sidebar__button = "Sidebar-module--sidebar__button--sB7qb";
export var sidebar__container = "Sidebar-module--sidebar__container--mUahX";
export var sidebar__content = "Sidebar-module--sidebar__content--RwOjQ";
export var sidebar__date = "Sidebar-module--sidebar__date--8sOgB";
export var sidebar__footer = "Sidebar-module--sidebar__footer--tlZAn";
export var sidebar__heading = "Sidebar-module--sidebar__heading--rd+52";
export var sidebar__icon = "Sidebar-module--sidebar__icon--Ysi5i";
export var sidebar__link = "Sidebar-module--sidebar__link--qHoYC";
export var sidebar__link__container = "Sidebar-module--sidebar__link__container--Ekiwu";
export var sidebar__link__container__bound = "Sidebar-module--sidebar__link__container__bound--anirz";
export var sidebar__link__container__icon = "Sidebar-module--sidebar__link__container__icon--abgOr";
export var sidebar__links = "Sidebar-module--sidebar__links--lFqq0";
export var sidebar__logos = "Sidebar-module--sidebar__logos--GCFAW";
export var sidebar__main = "Sidebar-module--sidebar__main--nqZSp";
export var sidebar__media = "Sidebar-module--sidebar__media--UpwBm";
export var sidebar_opened = "Sidebar-module--sidebar_opened--1M8yY";