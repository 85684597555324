import { TypeIcon } from 'core/types';

export const esteeLauder: TypeIcon = {
  viewBox: '0 0 85 50',
  path:
    '<path d="M27.8654 23.3724C28.2222 23.3724 28.6237 23.3724 28.9805 23.2832C28.8913 24.3983 28.8913 25.558 28.8021 28.2341V31.624C28.8021 33.7203 28.9805 35.0138 29.1589 35.549C29.8725 37.1993 31.3444 38.7605 33.9314 38.6712C36.2062 38.582 37.7673 37.8684 38.7039 35.9951C39.3284 34.7016 39.4176 33.8095 39.5068 30.5089V28.4126C39.4176 26.5838 39.3284 25.3795 39.2392 23.2832C39.5068 23.3278 39.7744 23.3724 40.042 23.3724C40.3097 23.3724 40.5773 23.3724 40.8449 23.2832C40.7557 23.9969 40.6665 27.9665 40.6665 27.9665V30.4643C40.6665 33.7649 40.4881 34.4786 40.1312 35.5936C39.9082 36.4411 39.4176 37.1993 38.7485 37.7792C37.6335 39.0727 35.8047 39.6971 33.6192 39.6971C31.6121 39.6971 29.6941 39.1619 28.5791 38.136C27.3748 36.9317 26.9288 35.8613 26.9288 33.3635V27.7881C26.9288 26.1378 26.7503 24.2199 26.6611 23.2832C27.0626 23.3724 27.464 23.417 27.8654 23.3724Z" fill="black"/>\n' +
    '<path d="M18.498 22.748C18.498 22.748 19.1224 24.3984 19.7022 25.6918C20.6389 27.5206 22.0662 30.9104 22.646 32.2931C23.1813 33.5866 24.6532 36.7088 24.6532 36.7088L25.9467 39.4742C25.5006 39.4742 25.1438 39.385 24.6532 39.385C24.2071 39.385 23.8057 39.385 23.3597 39.4742L22.423 37.1102C22.2446 36.4858 21.977 35.8613 21.6648 35.2815C21.3971 34.4786 21.0403 33.7204 20.6389 33.0067H14.5283C14.4391 33.2744 13.993 34.3894 13.6808 35.1923C13.0564 36.6196 12.5211 38.0915 12.1197 39.608C11.8521 39.5188 11.5845 39.4296 11.2723 39.4296C11.0046 39.4296 10.737 39.4296 10.4248 39.5188C10.4248 39.5188 10.8708 38.3145 11.4507 37.244C12.1643 35.5937 13.1902 33.4082 14.0376 31.5795C14.6621 30.1076 17.8735 23.0157 17.8735 22.8373H18.498V22.748ZM17.1153 27.1191C16.7584 27.8774 14.9297 31.9809 14.9297 31.9809H20.3267C20.3267 31.9809 19.39 29.8399 18.8548 28.5018C18.0519 26.6731 17.6505 25.9149 17.6505 26.0487C17.5167 26.4055 17.3383 26.8069 17.1153 27.1191Z" fill="black"/>\n' +
    '<path d="M45.4388 23.4616H46.643L48.4718 23.3724L50.0329 23.2832H50.4789C53.6011 23.2832 54.9838 23.64 56.5449 25.1119C58.106 26.5838 58.9535 28.6356 58.9089 30.8211C58.9089 33.4527 57.7492 35.9505 55.6974 37.6008C54.0471 38.9835 52.2184 39.4295 48.0703 39.3403H44.8589C44.4129 39.3403 44.0115 39.3849 43.5654 39.4295C43.6546 39.0727 43.7438 35.5936 43.7438 34.7462V30.7319C43.7438 29.7952 43.7438 27.3421 43.6546 25.9594C43.6546 25.1565 43.5654 23.3724 43.5654 23.3724C44.2345 23.4616 44.8143 23.5062 45.4388 23.4616ZM48.561 38.5374C49.4084 38.582 50.2559 38.5374 51.1479 38.4482C52.4414 38.3144 53.6457 37.69 54.5378 36.7087C55.9205 35.4152 56.5449 33.4081 56.5449 31.0887C56.4557 28.7248 55.9205 26.4946 54.2701 25.2903C52.9767 24.3983 52.263 24.1753 49.676 24.0861C48.4272 24.0861 47.1783 24.1753 45.9294 24.3537L45.751 24.4429C45.751 24.8889 45.6618 27.2083 45.6618 28.9478V35.9059C45.751 37.0209 45.751 37.6454 45.8402 38.4036C46.7322 38.5374 47.6689 38.582 48.561 38.5374Z" fill="black"/>\n' +
    '<path d="M61.4066 23.2826C63.5922 23.3718 64.5288 23.3718 65.5547 23.3718H69.569C69.569 23.5502 69.4798 23.8178 69.4798 23.9962C69.4798 24.1746 69.4798 24.6207 69.569 24.6207C69.0337 24.5315 68.4539 24.5315 67.651 24.4422C65.4655 24.353 64.8857 24.353 64.172 24.353H63.28V27.2076C63.1907 27.6983 63.1461 28.1889 63.1907 28.6795C63.1907 29.4824 63.28 30.3298 63.28 30.3298H67.205C67.8294 30.2852 68.4093 30.2406 69.0337 30.2406C68.9445 30.2406 68.9445 30.5083 68.9445 30.8651C68.9445 31.1327 69.0337 31.4895 68.9445 31.4895C68.0971 31.4003 66.2683 31.3111 64.8411 31.3111H63.2353V34.7009C63.2353 35.593 63.3245 36.262 63.3245 36.3513C63.3245 36.9757 63.3245 37.6447 63.4138 38.2692C63.6368 38.3584 63.9044 38.3584 64.1274 38.3584C66.0007 38.3138 67.874 38.1354 69.7028 37.9124C69.6582 38.1354 69.6136 38.3584 69.6136 38.5368V39.2505H63.2353C62.5217 39.2058 61.7635 39.2505 61.0498 39.3397C61.139 39.3397 61.3174 37.4217 61.3174 36.1282V34.1211L61.4066 31.1773C61.4066 31.1773 61.2282 24.13 61.139 23.1934H61.4066V23.2826Z" fill="black"/>\n' +
    '<path d="M4.09164 38.4036H5.29592C5.92036 38.4482 6.58941 38.4036 7.21385 38.3144C8.50734 38.3144 9.3994 38.136 9.66702 38.136V39.4295H9.13178C8.50734 39.4295 7.83829 39.3849 7.21385 39.3403H2.79815C1.50466 39.3403 0.880222 39.4295 0.791016 39.4295C0.880222 38.6266 1.05863 34.4786 1.05863 31.4456C1.05863 28.4126 0.791016 23.2832 0.791016 23.2832C1.14784 23.2832 1.63847 23.3724 1.9953 23.3724C2.35212 23.3724 3.19958 23.2832 3.19958 23.2832C2.97656 25.6472 2.88736 27.9665 2.93196 30.3305C2.93196 30.3305 2.93196 36.2181 3.02117 37.9576L3.11037 38.3144C3.06577 38.4036 3.5118 38.4036 4.09164 38.4036Z" fill="black"/>\n' +
    '<path d="M74.5199 31.624V34.4786L74.6091 36.7533L74.6983 39.2511V39.4295C74.2968 39.3849 73.8954 39.3403 73.494 39.3403C73.1372 39.3403 72.7357 39.3403 72.3789 39.4295L72.4681 36.9317C72.5573 35.103 72.5573 33.4527 72.5573 30.5981C72.5573 28.7694 72.5573 27.8327 72.4681 25.7364C72.4681 24.9335 72.3789 23.3724 72.3789 23.3724C72.9587 23.4616 73.5386 23.4616 74.1184 23.4616C74.5645 23.4616 75.3227 23.3724 75.9471 23.3724C77.0622 23.2832 77.7759 23.2832 77.9543 23.2832C81.7901 23.2832 82.9052 25.1119 82.9052 26.9407C82.9052 29.1262 80.9873 30.7765 77.7759 31.4456V31.5348L79.5154 33.6311L81.0765 35.549L82.816 37.7346C83.5297 38.6713 83.7527 39.0281 84.1987 39.4741H81.6117L78.8463 35.6828C78.1327 34.657 75.6349 31.6686 75.5457 31.6686L74.5199 31.624ZM75.8133 30.8211C77.6421 30.8211 78.3111 30.5535 79.114 30.0629C80.1844 29.483 80.8089 28.3233 80.7643 27.1191C80.6751 25.3795 79.6492 24.0861 76.9284 24.0861C76.1256 24.0861 75.3227 24.1753 74.5199 24.3537V30.6873C74.9213 30.8211 75.3673 30.8211 75.8133 30.8211Z" fill="black"/>\n' +
    '<path d="M16.4917 3.21074C14.4399 3.29994 13.2803 4.41502 13.2803 6.15454C13.2803 7.35882 13.9939 8.42929 15.109 8.91993C15.6442 9.09834 16.4917 9.54437 17.2945 9.85659C19.9707 10.704 21.0412 11.9529 21.0412 13.8709C21.0412 16.9039 18.4542 19.0894 15.2428 19.0894C13.7709 19.134 12.3436 18.6434 11.2285 17.7067C11.2285 17.7067 11.4961 16.4132 11.5853 15.878C11.5853 15.878 13.2357 18.465 15.5996 18.3312C17.5622 18.4204 19.2125 16.8593 19.2571 14.8967C19.2571 14.8521 19.2571 14.8075 19.2571 14.7629C19.2571 13.1126 18.2312 12.0867 15.8672 11.2839C13.5033 10.481 12.4774 9.54437 12.0314 8.78612C11.6745 8.16167 11.4961 7.49263 11.4961 6.77898C11.4961 4.192 13.5925 2.36328 16.6255 2.36328C19.0787 2.36328 20.2829 3.56756 20.2829 3.56756C20.0153 4.1474 19.7923 4.77184 19.6585 5.39629C19.7031 5.39629 18.7664 3.21074 16.4917 3.21074Z" fill="black"/>\n' +
    '<path d="M29.0245 3.92465L28.9353 6.60083L28.8461 9.81225C28.8015 10.8827 28.8015 11.9532 28.8461 13.0237C28.8015 14.9862 28.8907 16.9487 29.0245 18.9113C29.0245 18.9113 28.3109 18.8221 27.8203 18.8221C27.4634 18.8221 27.062 18.8667 26.7052 18.9113C26.7944 17.6178 26.8836 16.2351 26.8836 13.1129V6.60083C26.8836 6.60083 26.7944 4.59369 26.7944 3.92465L24.252 4.01385C23.4046 4.01385 22.5125 4.05845 21.665 4.19226C21.665 3.92465 21.7096 3.70163 21.7542 3.43401C21.7542 3.211 21.7096 2.98798 21.665 2.80957C21.8881 2.89878 27.5526 2.89878 27.5526 2.89878H28.3109C30.318 2.89878 33.9755 2.80957 33.9755 2.80957V3.47862C33.9755 3.74623 34.0201 3.96925 34.0647 4.23687C32.9496 4.05846 32.3252 4.05845 31.2101 3.96925H29.0245V3.92465Z" fill="black"/>\n' +
    '<path d="M1.14784 2.85417C3.33339 2.94338 4.18085 2.94338 5.25132 2.94338C5.87576 2.94338 8.55194 2.94338 8.99797 2.85417H9.26559C9.26559 3.03259 9.17638 3.3002 9.17638 3.47862V4.10306C8.55194 4.10306 7.9721 4.10306 7.34766 4.01385C5.20672 3.92465 4.53767 3.92465 3.91323 3.92465H2.97656V4.19226L2.88736 6.77924V9.81225C2.88736 9.85685 2.93196 9.90145 2.97656 9.90145H5.16211C5.42973 9.90145 5.87576 9.90145 6.90163 9.81225H8.64115V11.0165C7.70449 11.0165 5.96497 10.9273 4.40386 10.8381C3.91323 10.8381 3.42259 10.8381 2.93196 10.9273H2.88736V12.4884L2.97656 14.3172V15.9675C2.97656 16.3243 3.06577 17.1717 3.06577 17.7962L3.15498 17.8854H3.91323C5.83116 17.8854 8.59655 17.6178 9.48861 17.5286C9.444 17.7516 9.3994 17.9746 9.3994 18.153V18.8667H7.39226L5.83116 18.7775L2.88736 18.8667H0.791016C0.791016 18.8667 0.969428 17.0379 0.969428 15.7444L1.05863 13.7373V10.7935C1.05863 10.7935 0.969428 3.74623 0.880222 2.80957H1.14784V2.85417Z" fill="black"/>\n' +
    '<path d="M36.1162 1.02587H44.6354V0H36.1162V1.02587Z" fill="black"/>\n' +
    '<path d="M36.473 2.85515C38.6586 2.94436 39.5952 2.94436 40.6211 2.94436C41.2456 2.94436 44.0109 2.94436 44.3678 2.85515H44.7246C44.6354 3.07816 44.6354 3.30118 44.6354 3.47959V4.10403C44.0109 4.10403 43.3419 4.10403 42.7174 4.01483C40.6211 3.92562 39.9521 3.92562 39.2384 3.92562H38.3464V9.90243H40.6211C40.8887 9.90243 41.2456 9.90243 42.3606 9.81322H44.0555C44.0555 9.99163 44.0109 10.17 43.9663 10.3485C43.9663 10.5715 44.0109 10.7945 44.0555 10.9729C43.1189 10.9729 41.3794 10.8837 39.8183 10.7945C39.283 10.7945 38.7924 10.7945 38.2572 10.8837V12.4894L38.3464 14.3181V15.9684C38.3464 16.3253 38.4356 17.1727 38.4356 17.7972V17.8864H39.2384C41.0671 17.8864 43.9217 17.6188 44.7246 17.5295C44.68 17.7526 44.6354 17.9756 44.6354 18.154C44.6354 18.4216 44.6354 18.6446 44.7246 18.8676H42.7174L41.1563 18.7784L38.2126 18.8676H36.1162C36.1162 18.8676 36.2946 17.0389 36.2946 15.7454V13.7383L36.3838 10.7945C36.3838 10.7945 36.2946 3.74721 36.1162 2.81055L36.473 2.85515Z" fill="black"/>\n' +
    '<path d="M47.6683 2.85515C49.8539 2.94436 50.7906 2.94436 51.7718 2.94436C52.3963 2.94436 55.1617 2.94436 55.5185 2.85515H55.8753C55.7861 3.07816 55.7861 3.30118 55.7861 3.47959V4.10403C55.1617 4.10403 54.4926 4.10403 53.8682 4.01483C51.7718 3.92562 51.1028 3.92562 50.3891 3.92562H49.4971V9.90243H51.7718C52.0394 9.90243 52.3963 9.90243 53.5113 9.81322H55.2509C55.2509 9.99163 55.2063 10.17 55.1617 10.3485C55.1617 10.5715 55.2063 10.7945 55.2509 10.9729C54.3142 10.9729 52.5747 10.8837 51.0136 10.7945C50.4783 10.7945 49.9877 10.7945 49.4525 10.8837V12.4894L49.5417 14.3181V15.9684C49.5417 16.3253 49.6309 17.1727 49.6309 17.7972V17.8864H50.4337C52.2625 17.8864 55.1171 17.6188 55.9199 17.5295C55.8753 17.7526 55.8307 17.9756 55.8307 18.154C55.8307 18.4216 55.8307 18.6446 55.9199 18.8676H53.9128L52.3517 18.7784L49.4079 18.8676H47.3115C47.3115 18.8676 47.4899 17.0389 47.4899 15.7454V13.7383L47.5791 10.7945C47.5791 10.7945 47.4899 3.74721 47.3115 2.81055L47.6683 2.85515Z" fill="black"/>\n' +
    '<path d="M6.50052 48.7952L6.32211 49.4196C5.69767 49.8211 4.93942 49.9995 4.22577 49.9549C2.12943 49.9549 0.835938 48.6614 0.835938 46.565C0.835938 45.6284 1.19276 44.6917 1.86181 43.9781C2.62006 43.3982 3.55672 43.086 4.53799 43.1752C5.25164 43.1306 5.92068 43.2644 6.54512 43.532C6.45592 43.755 6.36671 44.0227 6.36671 44.2457H6.27751C5.78687 43.755 5.11783 43.4874 4.40418 43.532C3.64593 43.4874 2.88768 43.8443 2.39704 44.4241C1.95101 45.0485 1.728 45.7622 1.7726 46.5204C1.728 47.3233 1.99562 48.0815 2.48625 48.706C2.93228 49.2412 3.60133 49.5534 4.31497 49.5088C5.11783 49.5088 5.83148 49.2412 6.41132 48.706L6.50052 48.7952Z" fill="black"/>\n' +
    '<path d="M18.1417 46.5656C18.1417 48.662 16.759 49.9554 14.5735 49.9554C12.2987 49.9554 10.916 48.662 10.916 46.5656C10.916 44.4693 12.2987 43.1758 14.5735 43.1758C16.759 43.1758 18.1417 44.4693 18.1417 46.5656ZM12.4325 44.4693C12.0311 45.0937 11.8527 45.852 11.8973 46.5656C11.8527 47.2793 12.0311 47.9929 12.4325 48.6173C12.8785 49.331 13.6814 49.7324 14.5289 49.7324C15.3317 49.7324 16.09 49.331 16.536 48.6173C16.9374 48.0375 17.1158 47.3239 17.0712 46.6102C17.1158 45.852 16.8928 45.1383 16.536 44.5139C16.09 43.8448 15.3317 43.488 14.5289 43.488C13.726 43.4434 12.9232 43.8448 12.4325 44.4693Z" fill="black"/>\n' +
    '<path d="M24.074 43.1755C24.1632 43.3539 24.1632 43.3539 24.3417 43.7107C24.5201 43.9783 24.6093 44.2459 24.7877 44.5136L24.9661 44.9596C25.5905 46.0747 25.4121 45.8962 25.8582 46.6099C26.1258 47.1897 26.438 47.725 26.7948 48.2602C27.0178 47.8588 27.2855 47.4574 27.5531 47.0559C27.7315 46.6099 27.9545 46.1639 28.1775 45.7624L28.3559 45.3164L28.6236 44.9596L28.8912 44.5136C29.0696 44.1121 29.2926 43.7107 29.5156 43.3093L29.6048 43.1309H29.7832C29.8724 43.3093 29.8724 43.2201 29.8724 43.6661C29.917 44.1121 29.9616 44.6028 30.0508 45.0488V45.4056L30.1401 45.7624V46.4761C30.2293 46.7883 30.2739 47.1451 30.3185 47.502V47.8588L30.4077 48.617C30.4969 49.1523 30.4969 49.4199 30.5861 49.8213C30.4523 49.7767 30.2739 49.7321 30.1401 49.7321C30.0062 49.7321 29.8278 49.7767 29.694 49.8213C29.694 49.3753 29.694 48.8846 29.6048 48.4386C29.5156 46.9667 29.5156 46.9667 29.248 44.8704C29.025 45.138 28.8466 45.4502 28.7128 45.807C28.6236 45.9855 28.7128 45.807 28.3559 46.5207C28.1775 46.9221 27.9545 47.2789 27.7315 47.6358C27.107 48.9293 26.9286 49.1969 26.7056 49.6429H26.6164C26.5272 49.4645 26.5272 49.4645 26.2596 48.84C26.1704 48.6616 26.438 49.1077 25.5013 47.3681C25.3229 46.9667 25.1445 46.6099 24.8769 46.2531L24.6985 45.8962L24.5201 45.5394L24.3417 45.1826L24.2525 45.0934V45.0488C24.074 45.8962 23.9402 46.7437 23.8956 47.6358C23.8064 48.9293 23.8064 49.2861 23.7172 49.9105C23.5388 49.7767 23.2712 49.7767 23.0928 49.9105C23.182 49.5537 23.182 49.2861 23.2712 48.7954C23.5388 47.1451 23.4496 47.5912 23.4496 47.3235C23.5388 47.1005 23.5388 46.9221 23.5388 46.6991L23.628 46.3423C23.8064 45.2272 23.7172 45.807 23.8956 44.692C23.9848 43.7553 23.9848 43.7553 24.074 43.2201V43.1755Z" fill="black"/>\n' +
    '<path d="M36.8305 47.0569V49.3316C36.9197 49.51 36.9197 49.6885 36.9197 49.8669C36.7859 49.8223 36.6075 49.7777 36.4737 49.7777C36.2953 49.7777 36.1169 49.8223 35.9385 49.8669C36.0277 49.4654 36.0277 49.064 36.0277 48.6626C36.0277 48.0381 36.1169 46.9231 36.1169 46.477C36.1169 45.585 36.0277 44.9159 36.0277 44.2915C36.0277 43.9347 35.9831 43.6224 35.9385 43.2656C36.2061 43.3548 36.4737 43.3548 36.7413 43.3548C37.0089 43.3548 37.232 43.3102 37.4996 43.2656H38.0348C39.5067 43.2656 40.2204 43.8009 40.2204 44.9159C40.1758 45.5404 39.9081 46.1202 39.4175 46.477C38.8377 46.7447 38.1686 46.8785 37.4996 46.8339H36.8751V47.0569H36.8305ZM36.8305 46.477H37.2766C38.6593 46.477 39.2837 45.9418 39.2837 44.9159C39.2837 44.1131 38.7485 43.6225 37.6334 43.6225C37.3658 43.6225 37.1428 43.6224 36.8751 43.7117C36.8305 43.9347 36.7859 44.1577 36.7859 44.3361L36.8305 46.477Z" fill="black"/>\n' +
    '<path d="M46.8211 43.1758C46.9103 43.4434 46.8211 43.1758 47.0887 43.934C47.2225 44.2909 47.3563 44.6031 47.5348 44.9599C48.4714 47.0562 48.1592 46.4318 48.4714 47.1454L48.6498 47.6807C49.1851 48.7065 49.0066 48.3943 49.0959 48.7065C49.3635 49.331 49.4527 49.331 49.6311 49.9108C49.4973 49.8216 49.3189 49.8216 49.1851 49.8216C49.0066 49.8216 48.8282 49.8216 48.6498 49.9108C48.5606 49.4648 48.4268 49.0188 48.2038 48.6173C48.07 48.1713 47.8916 47.7253 47.6686 47.3239H45.3046C44.8586 48.4389 44.8586 48.3497 44.2787 49.9108C44.1895 49.8216 44.1003 49.8216 44.0111 49.8216C43.8773 49.8216 43.7435 49.8662 43.6543 49.9108C43.8327 49.4648 44.0111 49.108 44.3679 48.2605C44.6356 47.7253 44.814 47.1454 45.1262 46.6102C45.483 45.8074 45.7506 45.2275 45.8398 44.9599C46.0629 44.6477 46.1967 44.2909 46.2859 43.934C46.4197 43.7556 46.5089 43.5772 46.5535 43.3988L46.6427 43.3096V43.2204L46.8211 43.1758ZM45.4384 46.8778H47.5348C47.4455 46.744 47.3563 46.5656 47.2671 46.4318C46.8211 45.2275 46.9995 45.6289 46.8211 45.3167C46.7319 45.0491 46.5981 44.7369 46.4643 44.5139L45.4384 46.8778Z" fill="black"/>\n' +
    '<path d="M54.3594 43.1758L54.4486 43.265C54.7608 43.5326 55.0285 43.8002 55.2515 44.0678C55.5191 44.4247 55.8313 44.7815 56.1881 45.0937L56.4558 45.4505L56.8126 45.7181L57.0802 46.075C57.437 46.3872 57.7938 46.7886 58.1061 47.19L59.1319 48.2159V48.1267C59.2211 47.9483 59.1319 48.2159 59.2211 47.3239V46.1196C59.2211 45.3613 59.2211 44.6031 59.1319 43.8448V43.265C59.2211 43.3096 59.355 43.3542 59.4888 43.3542C59.578 43.3542 59.6672 43.3542 59.7564 43.265V44.0678C59.6672 44.6477 59.6672 45.2275 59.6672 45.8074C59.578 46.521 59.578 47.2793 59.578 47.9929V49.3756C59.578 49.554 59.6226 49.7324 59.6672 49.9108H59.4888C59.0873 49.3756 58.6859 48.885 58.1953 48.4389C57.7492 47.9929 57.2586 47.3239 56.8126 46.967C56.6342 46.6994 56.9018 47.0562 55.8759 45.9412L55.2515 45.3167C54.627 44.5585 54.8054 44.7815 54.7162 44.7815L54.627 44.6923V47.19C54.627 47.8591 54.627 48.5281 54.7162 49.1972V49.8216C54.627 49.7324 54.5378 49.7324 54.3594 49.7324C54.2702 49.7324 54.181 49.7324 54.0918 49.8216C54.0918 49.4202 54.0918 49.0188 54.181 48.6173V46.4318L54.2702 46.075V43.1758H54.3594Z" fill="black"/>\n' +
    '<path d="M65.822 43.3548C65.9558 43.3548 66.1342 43.3548 66.268 43.2656C66.268 43.6224 66.268 44.0239 66.1788 44.3807V48.7072C66.1788 49.1532 66.268 49.4208 66.268 49.9115C66.1342 49.8669 65.9558 49.8223 65.822 49.8223C65.6882 49.8223 65.5098 49.8223 65.376 49.9115V48.7072C65.376 48.3504 65.4206 47.9489 65.4652 47.5921V46.1202C65.4652 45.5404 65.4652 44.9605 65.376 44.3807V43.2656C65.5098 43.3548 65.6436 43.3548 65.822 43.3548Z" fill="black"/>\n' +
    '<path d="M73.3154 49.7777H72.2895C72.0665 49.7331 71.8435 49.7777 71.665 49.8669C71.665 49.4208 71.7543 48.9302 71.7543 48.0381V44.3807C71.7989 44.0239 71.7542 43.6224 71.665 43.2656C71.9327 43.2656 72.0219 43.3548 72.3787 43.3548H74.475C74.8319 43.3548 74.8318 43.2656 75.0103 43.2656V43.8009H74.2966C74.1182 43.8009 73.8506 43.7117 73.4492 43.7117H72.5571V45.2728C72.4679 45.808 72.4679 45.6296 72.5571 45.6296C72.4679 45.808 72.4679 45.808 72.4679 45.8972C72.4679 45.9864 72.4679 46.0756 72.5571 46.0756C72.6463 46.1648 72.7801 46.1648 72.9139 46.1648C73.1369 46.1648 73.36 46.1202 73.5384 46.0756H74.8319V46.6108C74.2966 46.5216 73.7168 46.477 73.1815 46.5216H72.4679C72.5125 46.5662 72.5571 46.6555 72.5571 46.7001L72.4679 47.4583C72.4679 47.7705 72.5125 48.0381 72.5571 48.3504V49.287C72.6463 49.3316 72.7801 49.3762 72.9139 49.3762C73.1815 49.3762 73.4492 49.3316 73.7614 49.287C74.2074 49.3316 74.698 49.287 75.1441 49.1978V49.8223H75.0549V49.7331L73.3154 49.7777Z" fill="black"/>\n' +
    '<path d="M82.5034 44.3797C82.325 43.889 81.8344 43.5768 81.2991 43.5768C80.7193 43.5322 80.1841 43.9336 80.0949 44.5581C80.0949 44.6027 80.0949 44.6473 80.0949 44.6919C80.0503 45.0487 80.1841 45.4055 80.4517 45.6286C80.8085 45.8516 81.1653 46.03 81.5668 46.1638C81.9682 46.2976 82.3696 46.5206 82.6818 46.7882C82.9495 47.0559 83.1279 47.4127 83.1279 47.8141C83.1279 49.1076 82.102 49.9997 80.7193 49.9997C80.1395 49.9997 79.6042 49.8212 79.1582 49.4644C79.2474 49.1968 79.292 48.9292 79.3366 48.6616H79.4258C79.6934 49.1968 80.2733 49.5536 80.8977 49.5982C81.6114 49.6428 82.2358 49.1076 82.2804 48.3939C82.2804 48.3493 82.2804 48.3047 82.2804 48.3047C82.2804 47.9925 82.1912 47.6357 82.0128 47.3681C81.656 47.1451 81.2991 46.9666 80.8977 46.8328C79.6934 46.3868 79.3366 45.9408 79.3366 45.0041C79.3366 44.5135 79.515 44.0674 79.8719 43.7106C80.3179 43.3984 80.8085 43.2646 81.3437 43.2646C81.879 43.22 82.4142 43.3984 82.8156 43.7106C82.6372 43.9336 82.548 44.1567 82.548 44.4689L82.5034 44.3797Z" fill="black"/>',
};
