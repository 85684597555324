import { TypeIcon } from 'core/types';

export const stories: TypeIcon = {
  viewBox: '0 0 213 40',
  path:
    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="213px" height="40px" viewBox="0 0 213 40" enable-background="new 0 0 213 40" xml:space="preserve">  <image id="image0" width="213" height="40" x="0" y="0"\n' +
    '    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANUAAAAoCAQAAADDRXfPAAAABGdBTUEAALGPC/xhBQAAACBjSFJN\n' +
    'AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ\n' +
    'cwAACxMAAAsTAQCanBgAAAAHdElNRQfmDAkOGSb3ovFbAAAIJElEQVR42u2bO1QbSRaGv9kz57gd\n' +
    '0RvRjtwTISLakZSJjcCRNRFssuAIJkJE4AgRjRyh2QQ7Ak8CG1lENhFMBETCESJyO7IcoYnojbSB\n' +
    'qqtf1d3VGnyw9+gnUHfV37cetx733ip+4OvCpowHPMSlw39laoW38m0MLfzw1SQbzGFyySf6gEWJ\n' +
    'KUwMHBwsPH6if9+NHwPAYgsnkepwwoABA24w77uKYwDMcJaiCoODsaq+HZh8ppyRezVW1beCt7zL\n' +
    'zK+OVfVtwGHAWg7nZKyq4vjbnUtcgFzb7vC+m/094u5VVdPgHOHdd8O/P9y1qkxsYD6H1ePyvhv+\n' +
    '/eFHRZrBY65T+AZTOJhcck0vhQE1rJRcH907bYWJjU2X3v+zW51U1QwNPlFXcG0m6HPNNSXWqHFO\n' +
    'kz8SLA8DgwN+zuy2sCI3sWRqh0+UeQJAm1ummeKhyN3jQ0KOQ5keLi4uJlNYWFwo5qwhylgUv21c\n' +
    'kTNJBY8uHxT1NYXxsyLqsMef8itVzeL8XRk8e0yJaSm3Kdpvy36+xeWSL6GyH7AspHR4o+rCRT4z\n' +
    'oKXIWeAdNR7Ipg9d2R2Z4qMj4hEnlDJUtRSyAIdffKaODUBLSHgmlLETeQ9gs8cORizV4oCtVPuy\n' +
    'o5BlssEtnYyvbsRXM5HUMu9Saubzw/IM5mSsxpfjiPd3zCXasSLjOk40o0qVKlsiO6mqErcMGLAb\n' +
    'Shlyt2JMv2MH3GY0vhJScYcBNyHFthIdUFd0SJWPKf6bwRlXKQOlk9K5u2J4qeurVhXAhraqhjXb\n' +
    'U6hqV1GiKWX8qu7AtVRVrUoFWLLYz0Ih0Qo5kumrq0I2Ogyoht5biu7ciL3PcZvhm5Viys9XlSWG\n' +
    '4tuCqhoqWVdVAG9jqlIPtz0h4WMgQ9cC9I3rvnzyhGlgxIJIl7wPvRk0OOOKjczlcF+x50XxW8S8\n' +
    'dzjE4H3qbtili8mBHFb56HEKQI0p7W+GWC/oeLyI8V8oOGUWxVMzaKOuqg55D3hsK7onvsr+krD+\n' +
    'SjS54oyFlHnwKrd8L8Qx2MEEjjP4bwAnbfFQwrd5a5p8fxh4tAvxuxH+e6Xb0hJ9esrrIFF/Vj1l\n' +
    'munI0mjLvChcnihN9QqHfGQjoa4eFxo1CCIcs8yKhqTjBIDlwnMEHmnyAsl6sZeAH15B9hXMJbFl\n' +
    '9FkPJxdxgbvSwAUoCVV5io7uxZQawKTJFQuRtEut0q/lkPhVlOFmsofYLNC+IW40eaZ8+kNrCQz4\n' +
    'nVBqV8FriadWtGdGj1b4W2NbuWP0WaeSsjRYHEbsRlerPH+XLAnjNdvF9kSt5rXDwpPi97Rg+8P7\n' +
    'dxaC2folxP+U4O2IGl/yWzRjdFXVRDVfpDIu+JlpGkpVNELRdxc9DJv1TOsrvzOsjJOzKBwAznMN\n' +
    'nCFs4aYP8SGX78ihAD2poG5imFdZFk/1eN6oqjJFnK+e02VdtpmmIv3zAA251x2jB1c0ugibUIwg\n' +
    'C/OUgD6/aErfiLz1C/G9kBUdhSGNp8PkkBlVVWuYQCMW8rCVnofHBS+YphYx482E86wHsyDf1uBM\n' +
    'sYtHm4rGvmkwxQGr2uUbVDiQxnc2VoRLo1yrftQSkSy+BjR5GUs3eZy6GPQ54og5GtIlznON1dD3\n' +
    'lbJgChU+4BFVHPY5opu751hYVJjXrrlFmQqLmT5lAJuGeGqq1qrRZtUKDvsJZw7g7zlfHvNUGrf2\n' +
    'SCfBd3PS1Rch3mvOOceiwoSG5B7HbPMPVjUj+EP+Eza1au2H4Vy19TzKrDLZ5DBq80s81uikf2JT\n' +
    'Iek666FXkJ9nfHscc80V8+yzrqUCj9fkn8mF+S812hoYFKvqy6yjzKotTnme0ihHS8LzvzA3dE+6\n' +
    'rAJ8lzawXCC68SbSgomC/OQibtIUT22FmWXBKKqaxUp0dRAlryQORVTocg543BYuHc7F72QObziO\n' +
    'PU0He8ha1jJChnLPQ2/5e5EbWQ2SMZQ1GaF4rvi6DMVVZfIsoSgzVFVLM4Z2Dbgj3Vo/FvM5e1f0\n' +
    'D/nOU8+zo/BDz3qWGkRjLHk7NEQN+visCo4YN9MPOIupyuBfbCu8gTBqWpI8iIxLfXjCLLEy56/f\n' +
    'Gf/RlOovk1VNfhQ6kcZgVln8FMvbFQMrEp6VEBOhmKq2+F2h9WhFa1rNnUQdqtTBSzzAzDSZHQAu\n' +
    'ta+xfZFhK10Ekc+ZRNdn8yuxWTUnTBQvJWIp+rOIqlZ4rZyezci7QTN3vzKZ5VQzhJOEK5o0m8EZ\n' +
    'hp/q2tdi/DiepbXXQthpqBes/1Ksv/wIxSvlCYPhK1DfWF+gojA5LWqJkVihnnCOo1gD7RCOCq9x\n' +
    'WGaR7ZT8YdirUWgwdIUD8Ui4n2WtoxkoUVNfU0nBHPORZW5DmDJuSp+t+N6nrqqqvCrgsDboZVS/\n' +
    'zCrLf+l6mcc6Bos840iZv4FJK2e4xHEpFtQZXMCUd5OyYXFQyJG3eRUZ8CU5J1tKj7EqIxgxpN2t\n' +
    'KIl7FGl/8Rs4A27ZSFlKFjnLiXa3Uu4/RGGwxZVyv5rjJrbIBOikyq6J2xV7ALEapt2tqHCl7C/1\n' +
    '3QqDmujHgO/feTpRtnBFSFqCYFaVeQjYYm2fEPvAmTCntzA0V/2AtUmVf0cCtDBFnT5PU2SZwhiY\n' +
    'EPkl/iTuwQTw2KZNnQkuQvJslrAU/xHpyx6kym7TZBODRXp0xWy1xOLun3450iyfwOYZT0SLff8w\n' +
    'zi9Ld2QCmwWmY/wqFcFsx3beSUrizqJsydf7B1MwWcLEFWu/jYnL8Z3fVjeZk0aBge6ZbJqsBTw+\n' +
    'jK9pjzHGGGN8U/gfFqrGMkN+oxgAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTItMDlUMTM6MjU6\n' +
    'MzgrMDE6MDB1OE3HAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEyLTA5VDEzOjI1OjM4KzAxOjAw\n' +
    'BGX1ewAAAABJRU5ErkJggg==" />\n' +
    '</svg>\n',
};
