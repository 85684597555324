// extracted by mini-css-extract-plugin
export var hamburger = "Header-module--hamburger--6SnD5";
export var hamburger__empty = "Header-module--hamburger__empty--nW3ut";
export var header = "Header-module--header--NXzL8";
export var languages = "Header-module--languages--7t9zw";
export var logo = "Header-module--logo--7bOtT";
export var logo__close = "Header-module--logo__close--ayTT1";
export var logo__icon = "Header-module--logo__icon--O5sBC";
export var logo__icon_menu = "Header-module--logo__icon_menu--y2lTF";
export var logo__icon_upper = "Header-module--logo__icon_upper--Wydsc";
export var logo_upper = "Header-module--logo_upper--zhSsE";
export var separator = "Header-module--separator--NkovZ";