import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

export function useWindowSize(
  { throttleMs }: { throttleMs?: number } = { throttleMs: 100 },
): { width: number; height: number } {
  const [width, setWidth] = useState(
    typeof window === 'undefined' ? 0 : window.innerWidth,
  );
  const [height, setHeight] = useState(
    typeof window === 'undefined' ? 0 : window.innerHeight,
  );

  useEffect(() => {
    const handleResize = throttle(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, throttleMs);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { width, height };
}
